<!-- 关于我们：防疫准则 -->
<template>
<div class="gulations">
    <div v-if="config.img&&config.img.isShow">
        <TopImg :web='config.img.pc' :wap='config.img.mobile'></TopImg>
    </div>
    <div class="wCenter">
        <div class="gulationsContent">
            <div v-if="config.c1&&config.c1.isShow">
                <p class="content" v-for="(item, index) in config.c1.descr.split('\n')" :key="index">{{item}}</p>
            </div>
            <p class="title1" v-if="config.c2&&config.c2.isShow">
                {{config.c2.title}}
            </p>

            <p class="title2" v-if="config.c3&&config.c3.isShow">
                {{config.c3.title}}
            </p>
            <div v-if="config.c3&&config.c3.isShow">
                <p class="content" v-for="(item, index) in config.c3.descr.split('\n')" :key="index">{{item}}</p>
            </div>

            <p class="title3" v-if="config.c4&&config.c4.isShow">
                {{config.c4.title}}
            </p>
            <div v-if="config.c4&&config.c4.isShow">
                <p class="content" v-for="(item, index) in config.c4.descr.split('\n')" :key="index">{{item}}</p>
            </div>
            <p class="title4" v-if="config.c5&&config.c5.isShow">
                {{config.c5.title}}
            </p>
            <div v-if="config.c5&&config.c5.isShow">
                <p class="content" v-for="(item, index) in config.c5.descr.split('\n')" :key="index">{{item}}</p>
            </div>
            <p class="title5" v-if="config.c6&&config.c6.isShow">
                {{config.c6.title}}
            </p>
            <div v-if="config.c6&&config.c6.isShow">
                <p class="content" v-for="(item, index) in config.c6.descr.split('\n')" :key="index">{{item}}</p>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    getPageConfigByCode,
} from '@/api/api'
import TopImg from '@/components/topImg'
export default {
    name: '',
    components: {
        TopImg,
    },
    data() {
        return {
            config: {},
        }
    },
    mounted() {
        this.getPageConfigByCode()
    },
    methods: {
        // 页面配置
        getPageConfigByCode() {
            const params = {
                code: 'guidelines/index',
            }
            getPageConfigByCode(params).then(res => {
                if (res.success) {
                    this.config = JSON.parse(res.result)

                }
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.gulations {
    .gulationsContent {
        margin-top: 0.3rem;

        .title1 {
            color: #ff1932;
            font-size: 0.3rem;
            margin: 0.4rem 0 0rem 0;
            font-weight: bold;
        }
		
        .title2,
        .title3,
        .title4,
        .title5 {
            font-size: 0.24rem;
            padding:.1rem .3rem .1rem 0;
            margin-bottom: 0.3rem;
			margin-top: 0.2rem;
        }
        .title2 {
            color: #ffffff;
            background: #000000;
		
        }

        .title3 {
            color: #ffffff;
            background: #ff1932;
        }

        .title4 {
            color: #ffffff;
            background: rgb(180,40,85);
        }

        .title5 {
            color: #000000;
            background: rgb(204,204,204);
        }

        .content {
            color: #333333;
            font-size: 0.24rem;
            line-height: 2;
            text-align: justify;
			margin-top: 0.2rem;
        }
    }
}


  @media (max-width: 767px) {
		.gulations {
		    .gulationsContent {
		        .title1 {
		          
		            font-size: 0.5rem;
		            margin: 0.4rem 0 0rem 0;
		           
		        }
		        .title2,
		        .title3,
		        .title4,
		        .title5 {
		            font-size: 0.46rem;
		            padding:.1rem .3rem .1rem 0;
		            margin-bottom: 0.3rem;
					margin-top: 0.6rem;
					
		        }
		        .title2 {
					margin-top: 0.2rem;	
		        }
		
		        .content {
		            font-size: 0.4rem;
					line-height: 1.8;
					margin-top: 0.4rem;
		           
		        }
		    }
		}   
        }
</style>
